import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Hero from "../../components/Hero";
import { Session } from "../../components/Session";
import { postData } from "../../utils/data/postData";
import { GlobalContext } from "../../context/globalContext";
import bgBlue from "../../_assets/bg-blue.png";
import bgWhite from "../../_assets/bg-white.png";
import Footer from "../../components/Footer";
import PostSlider from "../../components/PostSlider";
import { GridContainer } from "../../components/GridContainer";
import ProductItem from "../../components/ProductItem";
import { productsData } from "../../utils/data/productsData";
import ImagesGallery from "../../components/ImageGalery";
import { Link } from "react-router-dom";
import { imagesHome } from "../../utils/data/imageGaleryData";
import { parceirosData } from "../../utils/data/parceirosData.js";
import Carrousel from "../../components/Carrousel";
import { getDocs, collection, deleteDoc, doc  } from "firebase/firestore";
import { db } from "../../services/firebaseConfig.js";


const Home = () => {
    const {
        state: { posts },
        setState,
    } = useContext(GlobalContext);

    const productCollectionRef = collection(db, "produtos");
    const [products, setProducts] = useState([])

    useEffect(() => {
        if (posts.length === 0) {
            const newPosts = postData.map((post) => post);
            setState((prevContext) => ({
                ...prevContext,
                posts: newPosts,
            }));
        }
    }, [posts, setState]);

    useEffect(() => {
        const getProducts = async () => {
            const data = await getDocs(productCollectionRef)
            setProducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }
        getProducts()
    }, [])

    useEffect(() => {
        const newProducts = productsData.map((product) => product);
        setState((prevContext) => ({
            ...prevContext,
            products: newProducts,
        }));
    }, []);

    return (
        <>
            <Navbar />
            <Hero />
            <Session background="#ffff">
                {posts && posts.length > 0 ? (
                    <PostSlider items={posts} />
                ) : (
                    <p>Loading...</p>
                )}
            </Session>
            <Session background="#E6E6FA">
                <div className="container px-5">
                    <h1 className="display-2 text-center">O Cintesp.br</h1>
                    <p className="lead">
                        Com a missão desenvolver inovações em tecnologia
                        assistiva e viabilizar, em um menor tempo possível, a
                        transferência destas tecnologias para a sociedade e para
                        o setor produtivo, o CINTESP.Br/UFU estimulou e trabalha
                        em rede colaborativa e em articulação com agentes
                        socioeconômicos.
                    </p>
                    <p className="lead">
                        Sempre vinculado à Universidade Federal de Uberlândia, o
                        CINTESP.Br, iniciou os trabalhos em 2012 como “Núcleo De
                        Habilitação Reabilitação em Esportes Paralímpicos,
                        conhecido por NH/RESP. Ele foi criado para ser um dos
                        Núcleos brasileiros de Pesquisa e desenvolvimento em
                        tecnologia assistiva na área do esporte paralímpico.
                    </p>
                    <Link to={"/quemsomos"}>Leia mais</Link>
                </div>
            </Session>
            <Session backgroundimage={bgBlue}>
                <h1
                    style={{ color: "#ffffff" }}
                    className="display-2 text-center"
                >
                    Nossos Parceiros
                </h1>
                <Carrousel margin={10} items={5} cards={parceirosData} />
            </Session>
            <Session backgroundimage={bgWhite}>
                <div className="container px-5">
                    <h1 className="display-2 text-center">
                        Inovações Tecnológicas
                    </h1>
                    <p className="lead">
                        Atualmente, o Brasil é uma potência paralímpica, estando
                        entre as dez forças do esporte paralímpico mundial.
                        Desde os jogos de Londres, em 2012, onde os atletas
                        Brasileiros atingiram a 7ª colocação no quadro de
                        medalhas, o esporte paralímpico cresceu alcançando o 8º
                        lugar no ranking de medalhas na Olimpíada de 2016. Para
                        isso, os investimentos estatais foram fundamentais e o
                        papel do Comitê Paralímpico Brasileiro (CPB) vêm sendo
                        de fundamental importância para esta evolução. Neste
                        caso, o Centro Paralímpico Brasileiro, inaugurado em
                        fevereiro de 2016, vêm auxiliando os diversos atletas
                        com uma grande infraestrutura para treinamento em
                        diversas modalidades esportivas.
                    </p>
                    <Link to={'/produtos'}>Leia mais</Link>
                    <GridContainer>
                        {products.map((product) => {
                            return <ProductItem product={product} />;
                        })}
                    </GridContainer>
                </div>
            </Session>
            <Session backgroundimage={bgBlue}>
                <div className="container p-5">
                    <h1
                        style={{ color: "#ffffff" }}
                        className="display-2 text-center"
                    >
                        Cintesp.br em Eventos
                    </h1>
                    <p
                        style={{ color: "#ffffff" }}
                        className="lead text-center"
                    >
                        Confira algumas fotos de atuações do Cintesp.br em
                        eventos
                    </p>
                </div>
                <ImagesGallery images={imagesHome} />
            </Session>
            <Footer />
        </>
    );
};
export default Home;
