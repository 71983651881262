import "./sliderStyles.css";
import moment from 'moment'
import { Link } from "react-router-dom";

const SlideItem = (props) => {
    const slideItem = props.item;
    const publicationDate = slideItem.publicacao;
    const formattedDate = publicationDate ? moment(publicationDate).format('DD/MM/YYYY') : 'N/A';
    return (
        <Link to={slideItem.Link}>
            <div className="carousel-item active">
                <img
                    src={slideItem.image}
                    className="d-block w-100"
                    alt={slideItem.titulo}
                />
                <div className="carousel-caption d-none d-md-block slide-info">
                    <h5>{slideItem.titulo}</h5>
                    <p>{formattedDate}</p>
                </div>
            </div>
        </Link>
    );
};
export default SlideItem;
