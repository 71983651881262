import InnerPageContent from "../../components/InnerPageContent";
import InnerPageHeader from "../../components/InnerPageHeader";
import InnerPagesLayout from "../InnerPagesLayout";

const Missao = () => {
    return (
        <InnerPagesLayout>
            <InnerPageHeader title={"Missão, Visão e Valores"} />
            <InnerPageContent>
                <h1 className="display-6">Missão</h1>
                <p className="lead">
                    O CINTESP.Br tem por missão desenvolver inovações
                    tecnológicas assistiva e viabilizar, em um menor tempo
                    possível, a transferência destas tecnologias para a
                    sociedade e ao setor produtivo em articulação com agentes
                    socioeconômicos.
                </p>
                <h1 className="display-6">Visão</h1>
                <p className="lead">
                    Ser referência nacional no desenvolvimento de inovações
                    tecnológicas assistiva em esporte, saúde, lazer, vida diária
                    e doenças raras.
                </p>
                <h1 className="display-6">Objetivos</h1>
                <p className="lead">
                    O CINTESP.Br tem por objetivo desenvolver tecnologias
                    assistivas aplicadas para o aumento da acessibilidade das
                    pessoas com deficiência, mobilidade reduzida e com doenças
                    raras visando a melhoria da qualidade de vida, promoção da
                    saúde e da inclusão social.
                </p>
                <h1 className="display-6">Valores</h1>
                <ul>
                    <li className="lead">Ética e transparência</li>
                    <li className="lead">Comprometimento com a ciência</li>
                    <li className="lead">Rigor científico</li>
                    <li className="lead">Respeito às pessoas e à sociedade</li>
                    <li className="lead">Trabalho em equipe</li>
                    <li className="lead">Respeito ao meio ambiente</li>
                    <li className="lead">
                        Comprometimento com a inclusão social e acessibilidade
                        das pessoas com deficiência e mobilidade reduzida
                    </li>
                </ul>

                <h1 className="display-6">Público alvo e área temática</h1>
                <p className="lead">
                    O público alvo do CINTESP.Br são Pessoas com deficiência
                    (Adultos, crianças e idosos), Pessoas com mobilidade
                    reduzida e Atletas Paralímpicos. A área de atuação do
                    CINTESP.Br é inserida no tema “DESENVOLVIMENTO DE INOVAÇÕES
                    TECNOLÓGICAS PARA O ESPORTE PARALÍMPICO”
                </p>
            </InnerPageContent>
        </InnerPagesLayout>
    );
};
export default Missao;
