import { Link } from "react-router-dom";
import Logo from "../Logo";
import "./style.css";
import { GlobalContext } from "../../context/globalContext";
import { useContext } from "react";
import UserInfo from "../UserInfo";

const Navbar = () => {
    const {
        state: { isAuthenticated, currentUser }
    } = useContext(GlobalContext);
    return (
        <>
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <Logo />
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarText"
                        aria-controls="navbarText"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item dropdown mx-2">
                                <Link
                                    className="item-nav nav-link dropdown-toggle"
                                    id="navbarDropdownMenuLink"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    O CINTESP.BR
                                </Link>
                                <ul
                                    className="dropdown-menu"
                                    aria-labelledby="navbarDropdownMenuLink"
                                >
                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to={"/historico"}
                                        >
                                            {" "}
                                            Histórico
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to={"/quemsomos"}
                                        >
                                            Quem Somos
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to={"/missao"}
                                        >
                                            Missão, Visão e Valores
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown me-3">
                                <Link
                                    className="item-nav nav-link dropdown-toggle"
                                    id="navbarDropdownMenuLink"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    INSTITUCIONAL
                                </Link>
                                <ul
                                    className="dropdown-menu"
                                    aria-labelledby="navbarDropdownMenuLink"
                                >
                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to={"/formas-de-atuacao"}
                                        >
                                            Formas de Atuação e Redes
                                            Colaborativas
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to={"/instalacoes"}
                                        >
                                            Sede e Instalações
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown  me-3">
                                <Link
                                    className="item-nav nav-link dropdown-toggle"
                                    id="navbarDropdownMenuLink"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    PARCERIAS
                                </Link>
                                <ul
                                    className="dropdown-menu"
                                    aria-labelledby="navbarDropdownMenuLink"
                                >
                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to={"/parceria"}
                                        >
                                            Parcerias
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            className="dropdown-item"
                                            to={"/pesquisadores"}
                                        >
                                            Pesquisadores
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item">
                                <Link
                                    className="item-nav nav-link me-3"
                                    to={"/produtos"}
                                >
                                    PRODUTOS
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="item-nav nav-link me-3"
                                    to={"/cnt"}
                                >
                                    CNT
                                </Link>
                            </li>

                            <li className="nav-item">
                                <a
                                    className="item-nav nav-link me-3"
                                    href="#footer"
                                >
                                    CONTATO
                                </a>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="item-nav nav-link me-3"
                                    to={"/blog"}
                                >
                                    BLOG
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {!isAuthenticated && (
                        <Link to={"/login"}>
                            <button className="btn btn-primary">Login</button>
                        </Link>
                    )}
                </div>
            </nav>
            {isAuthenticated && <UserInfo user={currentUser} />}
        </>
    );
};

export default Navbar;
