import { useContext } from "react";
import { GlobalContext } from "../../context/globalContext";
import { useNavigate  } from "react-router-dom";

const ProductItem = (props) => {
    const item = props.product;

    const { setState } = useContext(GlobalContext);
    const navigate = useNavigate();

    const productDetail = (item) => {
        setState((prevContext) => ({
            ...prevContext,
            productDetail: item,
        }));
        navigate("/produtos/detalhe")
    }


    return (
        <div className="product-display" key={item.id} onClick={() => productDetail(item)}>
            <img
                width={"180px"}
                height={"180px"}
                src={item.imageDestak}
                alt=""
            />
            <div style={{width: "180px"}}>
                 <h6>{item.name}</h6>
            </div>
        </div>
    );
};
export default ProductItem;
