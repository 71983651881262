import InnerPageContent from "../../components/InnerPageContent";
import InnerPageHeader from "../../components/InnerPageHeader";
import InnerPagesLayout from "../InnerPagesLayout";

const Historico = () => {
    return (
        <InnerPagesLayout>
            <InnerPageHeader title={"Histórico"} />
            <InnerPageContent>
                <p className="lead">
                    Com a missão desenvolver inovações em tecnologia assistiva e
                    viabilizar, em um menor tempo possível, a transferência
                    destas tecnologias para a sociedade e para o setor
                    produtivo, o CINTESP.Br/UFU estimulou e trabalha em rede
                    colaborativa e em articulação com agentes socioeconômicos.
                </p>
                <p className="lead">
                    Sempre vinculado à Universidade Federal de Uberlândia, o
                    CINTESP.Br, iniciou os trabalhos em 2012 como “Núcleo De
                    Habilitação Reabilitação em Esportes Paralímpicos, conhecido
                    por NH/RESP. Ele foi criado para ser um dos Núcleos
                    brasileiros de Pesquisa e desenvolvimento em tecnologia
                    assistiva na área do esporte paralímpico.
                </p>
                <p className="lead">
                    Com a estrutura e pesquisas ampliando logo, a partir de
                    novembro de 2019, em função de diversos projetos inovadores
                    na área do Esporte Paralímpico, o NH/RESP passou a
                    denominar-se, Centro Brasileiro de Referência em Inovações
                    Tecnológicas para Esportes Paralímpicos – CINTESP.Br/UFU.
                    Com o esforço dos pesquisadores e Universidade Federal de
                    Uberlândia os trabalhos em inovação tecnológica assistiva
                    somou à vocação do Município de Uberlândia de descobrir,
                    apoiar e formar paratletas de alto rendimento estendendo
                    ainda as pesquisas para atender aos setores da educação, do
                    lazer, da saúde, para oferecer melhor qualidade de vida para
                    pessoas com deficiência, com doenças raras e com mobilidade
                    reduzida..
                </p>
                <p className="lead">
                    Em 2019 CINTESP.Br/UFU recebeu financiamentos do Ministério
                    da Ciência, Tecnologia e Inovações (MCTI) e do Ministério
                    Público do Trabalho/Região Uberlândia (MPT/Uberlândia) para
                    desenvolver, em parceria com o Comitê Paralímpico Brasileiro
                    (CPB), “Inovações Tecnológicas” em esportes paralímpicos. O
                    aporte financeiro fortalece as ações e gera uma rede
                    colaborativa com pesquisadores de outros estados, empresas,
                    pessoas com deficiência, atletas/paratletas e somando
                    parcerias com instituições de serviços e a Prefeitura
                    Municipal, por meio da Fundação de Esporte Turismo e Lazer
                    (FUTEL) fortalece o ambiente que Uberlândia vem construindo
                    como referência na iniciação e apoio ao esporte paralímpico
                    torando cada vez mais forte a estrutura esportiva do
                    Município sede, a cidade de Uberlândia.
                </p>

                <h1 className="display-6">Da estrutura do CINTESP.Br/UFU</h1>
                <p className="lead">
                    <b>Unidade 1 para a elaboração de projetos –</b> Laboratório
                    de Projetos Mecânicos “Prof. Henner Alberto Gomide”, no
                    Campus Santa Mônica da UFU.
                </p>
                <p className="lead">
                    <b>Unidade 2</b> para projetos de engenharia e design com
                    modelagem computacional e fabricação 3D – Laboratórios na
                    Arena Sabiazinho, da FUTEL
                </p>
                <p className="lead">
                    <b>Unidade 3 </b> para testes em ambiente relevante –
                    Estrutura de esportes do SESI Gravatás/FIEMG, com espaços
                    olímpicos e paralímpicos e pista com homologação
                    internacional
                </p>
            </InnerPageContent>
        </InnerPagesLayout>
    );
};
export default Historico;
