import InnerPageContent from "../../components/InnerPageContent";
import InnerPageHeader from "../../components/InnerPageHeader";
import InnerPagesLayout from "../InnerPagesLayout";

const QuemSomos = () => {
    return (
        <InnerPagesLayout>
            <InnerPageHeader title={"Quem Somos"} />
            <InnerPageContent>
                <p className="lead">
                    <b>
                        {" "}
                        O Centro Brasileiro de Referência em Inovação
                        Tecnológica Assistiva – CINTESP.Br{" "}
                    </b>{" "}
                    é um centro multidisciplinar de pesquisa e inovação em
                    tecnologias assistiva vinculado à Universidade Federal de
                    Uberlândia, que propicia a centralização, organização e
                    gestão de projetos, pesquisas e extensão desenvolvendo
                    Inovações Tecnológicas Assistiva no Esporte, Lazer, Saúde,
                    Vida Diária e Doenças Raras, com o apoio do Ministério da
                    Ciência, Tecnologia e Inovações (MCTI).
                </p>
                <p className="lead">
                    Partindo do pressuposto de que a atividade científica é
                    necessária para o desenvolvimento das Tecnologias Assistiva,
                    o CINTESP.Br busca minimizar o abismo entre a realidade
                    inclusiva na área do esporte, saúde, lazer, vida diária e
                    doenças raras seguindo uma política de igualdade para poder
                    auxiliar as pessoas com deficiência. Paralelamente, busca
                    fortalecer parcerias vigentes, além de buscar novas
                    parcerias institucionais, governamentais e com a comunidade
                    cientifica, identificando e articulando, a partir do
                    conceito de Redes Colaborativas, grupos de pesquisa,
                    pesquisadores e inventores que atuam na área de tecnologias
                    assistiva em diversas áreas, em especial no esporte, voltada
                    para qualificar a prática paradesportiva em alinhamento com
                    demandas de atletas paralímpicos e do Comitê Paralímpico
                    Brasileiro – CPB.
                </p>
                <p className="lead">
                    Funcionando como uma rede colaborativa de abrangência
                    nacional, o CINTESP.Br desenvolve ações considerando
                    interesses nacionais para a promoção da ciência, da
                    tecnologia e da inovação, como mecanismo para geração de
                    conhecimento, riqueza e qualidade de vida para os
                    brasileiros.
                </p>
                <p className="lead">
                    As ações incluem o credenciamento e a transferência de
                    tecnologias nacionais já desenvolvidas ou em desenvolvimento
                    que atendam às demandas pontuais de atenção à saúde, vida
                    diária, lazer, doenças raras e, em especial, a prática de
                    esportes paralímpicos.
                </p>
                <p className="lead">
                    No intuito de buscar soluções que amenizem questões
                    enfrentadas em políticas inclusivas, bem como no atendimento
                    às demandas atuais no campo de pesquisas e desenvolvimento
                    de tecnologias Assistiva, o CINTESP.Br busca planejar,
                    elaborar e desenvolver inovações tecnológicas em parceria
                    com outras instituições, organizações públicas, organizações
                    privadas, empresas e associações nacionais e internacionais
                    e pesquisadores interessados nos temas, esporte, saúde,
                    lazer, vida diária e doenças raras. Estes planejamentos
                    visam a consolidação de tecnologias em produtos que possam
                    ser transferidos para a indústria nacional, com o objetivo
                    de fortalecer o setor produtivo de equipamentos assistivos,
                    dispositivos esportivos, além de desenvolver novos métodos e
                    protocolos associados com as inovações tecnológicas
                    assistivas.
                </p>
            </InnerPageContent>
        </InnerPagesLayout>
    );
};
export default QuemSomos;
