
import destaque2 from "../../_assets/destaks/destSBPC.png";
import destaque3 from "../../_assets/destaks/destEsporte.png";
import destaque from "../../_assets/destaks/CPBRegras.png";

const postData = [
    {
        titulo: "Conheça a Regra 40 sobre patrocínios e publicidade durante os Jogos Paralímpicos de Paris 2024",
        conteudo:
            <>
            <p> Patrocinadores que investem no Movimento Paralímpico nacional e internacional são fundamentais para o sucesso de atletas, modalidades, clubes e confederações.
            No período dos Jogos Paralímpicos é necessário encontrar um equilíbrio entre o reconhecimento do patrocínio individual e o papel dos patrocinadores globais na realização dos Jogos Paralímpicos e na sustentação do paradesporto.
            A Regra 40 é uma forma de garantir que somente os patrocinadores globais possam utilizar as propriedades comerciais paralímpicas durante os Jogos. Também é uma forma de delimitar as responsabilidades dos atletas com patrocinadores individuais, e o que demais patrocinadores e emissoras de comunicação podem fazer ao utilizarem atletas paralímpicos para promover suas campanhas antes e durante os Jogos Paralímpicos de Paris 2024.
            </p>
           </>
        ,
        categoria: "Destaque",
        status: "Publicado",
        image: destaque,
        publicacao: '2023-09-21 16:47:56',
        modificado: new Date(),
        link:'https://cpb.org.br/noticias/conheca-a-regra-40-sobre-patrocinios-e-publicidade-durante-os-jogos-paralimpicos-de-paris-2024/',
        id: 1,
    },

    {
        titulo: "Esporte na qualidade de vida.",
        conteudo:
            <>
                <h2>A matéria sobre o Cintesp.br e a importância para inclusão e autoestima foi ao ar pelo Canal Futura na primeira temporada do Programa Alô Comunidade.</h2>
                [video width="848" height="480" mp4="https://cintespbr.org/wp-content/uploads/2023/09/VID-20230903-WA0016.mp4"][/video]
             </>,

        categoria: "Destaque",
        status: "Publicado",
        image: destaque3,
        publicacao: '2023-09-04 16:30:51',
        modificado: new Date(),
        link:'https://cpb.org.br/noticias/conheca-a-regra-40-sobre-patrocinios-e-publicidade-durante-os-jogos-paralimpicos-de-paris-2024/',

        id: 2,
    },

    {
        titulo: "CINTESP.Br/UFU na 75ª reunião da Sociedade Brasileira para o Progresso da Ciência (SBPC)",
        conteudo:
            <>
                [vc_row][vc_column width="1/2"][vc_column_text]

                [caption id="attachment_8381" align="aligncenter" width="1381"]<img class="wp-image-8381 size-full" src="https://cintespbr.org/wp-content/uploads/2023/08/img4-1.jpg" alt="" width="1381" height="1181" /> Pesquisador e coordenador do Cintesp.Br/UFU, Cleudmar Araújo no stand.[/caption]

                [/vc_column_text][/vc_column][vc_column width="1/2"][vc_column_text]

                [caption id="attachment_8378" align="alignnone" width="1382"]<img class="wp-image-8378 size-full" src="https://cintespbr.org/wp-content/uploads/2023/08/img1-1.jpg" alt="" width="1382" height="1181" /> Ministra Luciana Santos, MCTI conhecendo ações do Cintesp.Br/UFU e jornalista Cristiane de Paula[/caption]

                [/vc_column_text][/vc_column][/vc_row][vc_row][vc_column][vc_column_text]<span style="font-size: 10pt;">Texto: Cristiane de Paula – jornalista Cintesp.Br/UFU</span>
                <p style="text-align: justify;"><span style="font-size: 12pt;">O Centro Brasileiro de Referência em Inovações Tecnológicas para Esportes Paralímpicos da Universidade Federal de Uberlândia (Cintesp.Br/UFU) esteve presente na 75ª Reunião Anual da Sociedade Brasileira para o Progresso da Ciência (SBPC). O evento aconteceu na Universidade Federal do Paraná na cidade de Curitiba, entre os dias 23 a 29 de julho/2023.</span></p>
                <p style="text-align: justify;"><span style="font-size: 12pt;">A reunião da SBPC, deste ano, considerada como uma das maiores mostras de inovação da América Latina com a presença de diversos pesquisadores, onde foram realizadas 62 mesas-redondas, 41 conferências, 13 sessões especiais, 04 assembleias, 129 trabalhos apresentados e 244 trabalhos expostos na sessão de pôsteres. O evento contou com um total de mais de 10.000 pessoas inscritas, além de 700 participantes nos web-minicursos.</span></p>
                <p style="text-align: justify;"><span style="font-size: 12pt;">Foi neste cenário que o Cintesp.Br/UFU, com uma grande interação do público, apresentou equipamentos e dispositivos inovadores já patenteados, e já licenciados prontos para uso pela sociedade brasileira. A apresentação chamou a atenção pela variedade de equipamentos que atendem, não apenas ao esporte, como também à vida diária, lazer, educação inclusiva e saúde. Neste caso, a exposição de dispositivos apresentava soluções que podem ser aplicadas no cotidiano para promoção da inclusão de pessoas cegas. Por exemplo, jogos com peças em relevo para identificação por pessoas cegas ou com baixa visão. “A tecnologia, a ciência, o conhecimento têm que ter compromisso social. A Universidade Federal de Uberlândia tem um trabalho fundamental para o esporte paralímpico na produção de tecnologia assistiva. Isso é uma ação de pesquisa aliada à extensão para que as pessoas no Brasil todos se beneficiem com esse conhecimento tecnológico”, disse Hélder Eterno, Pró-Reitor de Extensão e Cultura da UFU, presente na SBPC.</span></p>
                <p style="text-align: justify;"><span style="font-size: 12pt;">“Nós precisamos sempre mostrar para a sociedade e para nosso governo o que o Cintesp.Br desenvolve para a população e continuar a buscar apoio para entregar mais tecnologias inovadoras para as pessoas”, disse Cleudmar Araújo, coordenador geral do Cintesp.Br/UFU que acompanhou o evento na 75ª Reunião Anual da SBPC.</span></p>
                <p style="text-align: justify;"><span style="font-size: 12pt;">O Cintesp.Br/UFU desde 2012, desenvolve inovações em TA, buscando soluções para a sociedade, principalmente, para as pessoas com deficiência de baixa renda, com o intuito de acelerar a produção de PD&amp;I e licenciamentos. Um dos objetivos é gerar um ambiente empreendedor para que as tecnologias sejam direcionadas para toda a sociedade. “O que mais me chamou atenção no Cintesp.Br/UFU é a capacidade de tirar do papel as pesquisas em tecnologia assistiva, onde o maior desafio para quem faz TA é justamente viabilizar em produto para quem de fato precisa”, disse Emerson da Costa, pesquisador em TA e vice-presidente da Associação Brasileira de incentivo à Ciência (Abric).</span></p>
                <p style="text-align: justify;"><span style="font-size: 12pt;">Entre os representantes do Ministério passaram pelo stand do Cintesp.Br/UFU e conheceram os equipamentos: Luis Fernandes, Secretário-Executivo do MCTI, e membro do Conselho de Administração da Finep, Inácio Arruda, Secretário de Ciência e Tecnologia para o Desenvolvimento Social, “tive uma percepção muito boa pela resposta que deu à população para atender pessoas com deficiência do nosso país. O que nós estamos vendo aqui, produzido em Uberlândia, é uma mostra da potência do país, temos cerca de 16 milhões de pessoas que precisam dessa tecnologia e essa é a resposta que a ciência está oferecendo, você vai desde a pesquisas básica, aplicada e inovação e entrega para a sociedade e também chamar as empresas, sobretudo as nacionais, que podemos oferecer essa tecnologia”, disse Inácio Arruda.</span></p>
                [/vc_column_text][/vc_column][/vc_row][vc_row][vc_column width="1/2"][vc_column_text]

                [caption id="attachment_8379" align="alignnone" width="1383"]<img class="wp-image-8379 size-full" src="https://cintespbr.org/wp-content/uploads/2023/08/img2-1.jpg" alt="" width="1383" height="1182" /> Visitação no stand e experimentação na petra-bike running, equipamento com inovações desenvolvidas pelo Cintesp.Br/UFU.[/caption]

                [caption id="attachment_8379" align="alignnone" width="1383"]<img class="wp-image-8379 size-full" src="https://cintespbr.org/wp-content/uploads/2023/08/img2-1.jpg" alt="" width="1383" height="1182" /> Área MCTI - Stands da ciência, tecnologia, inovação e empreendedorismo[/caption]

                <p style="text-align: justify;"><span style="font-size: 12pt;">Nesse período de mais de 10 anos, coordenou mais de 40 projetos de PD&amp;I em Tecnologia Assistiva (TA) por meio de redes colaborativas multidisciplinares envolvendo, mais de 60 pesquisadores e diversas parceiros como FUTEL/Uberlândia, Praia Clube, Instituto Brain/Algar, Sesi-Gravatás, dentre outros. As pesquisas financiadas pelo Ministério da Ciência, Tecnologia e Inovação (MCTI), Ministério Público do Trabalho (MPT-Uberlândia), CNPq, FAPEMIG, CAPES, FINEP, dentre outros.</span></p>
                <p style="text-align: justify;"><span style="font-size: 12pt;">Em visita ao stand do Cintesp.Br/UFU, a ministra do MCTI, Luciana Santos conheceu algumas das tecnologias inovadoras expostas, e recebeu o portfólio contendo as 34 tecnologias inovadoras em TA já disponíveis para toda a sociedade brasileira. “Tecnologia assistiva é uma de nossas prioridades”, disse a ministra do MCTI, Luciana Santos.</span></p>
                [/vc_column_text][/vc_column][/vc_row]
             </>,

        categoria: "Destaque",
        status: "Publicado",
        image: destaque2,
        publicacao: '2023-08-16 23:53:58',
        modificado: new Date(),
        link:'https://cpb.org.br/noticias/conheca-a-regra-40-sobre-patrocinios-e-publicidade-durante-os-jogos-paralimpicos-de-paris-2024/',
        id: 3,
    },

];
export { postData };
